import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';


import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';
import './app.css'
// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { Widget, addResponseMessage, addLinkSnippet } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import Loader from 'ui-component/Loader';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    useEffect(() => {
        addResponseMessage('You have a message from field agent Ajith');
        addResponseMessage(
            'Animal Radha, Animal Type : Cow Animal Subtype : Jersy, Issue : Body temperature, blisters in and around mouth and hoofs and saliva from nose and mouth. Infected animals cannot walk, and faces constipation'
        );
        addLinkSnippet({
            link: 'https://delivery.proagri-cdn.co.za/new_site/wp-content/uploads/2019/02/foot-and-mouth-disease-cattle-1.jpg'
        });
    }, []);

    const handleNewUserMessage = (newMessage) => {
        console.log(`New message incoming! ${newMessage}`);
        // Now send the message throught the backend API
        // addResponseMessage(response);
    };

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
            <Loader />
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                    {/* <Widget handleNewUserMessage={handleNewUserMessage} subtitle="" /> */}
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
