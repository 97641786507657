import dashboard from './dashboard';
import pages from './pages';
import utilities, { utiitiesWithAdmin ,utilitiesFinance} from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, utilities]
};
export const menuItemsWithAdmin = {
    items: [dashboard, utiitiesWithAdmin]
};
export const menuItemsFinance = {
    items: [dashboard, utilitiesFinance]
};

export default menuItems;
