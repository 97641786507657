import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { element } from 'prop-types';


// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsFieldAgent = Loadable(lazy(() => import('views/utilities/FieldAgent')));
const UtilsAdmins = Loadable(lazy(() => import('views/utilities/AdminComponent')));
const UtilsSchedule = Loadable(lazy(() => import('views/utilities/Schedule')));
const UtilsFarmer = Loadable(lazy(() => import('views/utilities/Farmer')));
const UtilsDoctor = Loadable(lazy(() => import('views/utilities/DoctorComponent')));
const UtilsService = Loadable(lazy(() => import('views/utilities/Service')));
const UtilsMedical = Loadable(lazy(() => import('views/utilities/Medical')));
const UtilsInventory=Loadable(lazy(() => import('views/utilities/Inventory')));
const UtilsSymptom = Loadable(lazy(() => import('views/utilities/Symptoms')));
const UtilsLivestock = Loadable(lazy(() => import('views/utilities/Livestock')));
const UtilsInvoices = Loadable(lazy(() => import('views/utilities/Invoices')));
const UtilsLivestockType = Loadable(lazy(() => import('views/utilities/LivestockType')));
const UtilsChat = Loadable(lazy(() => import('views/utilities/Chat')));
const UtilsLivestockMembershipComponent = Loadable(lazy(() => import('views/utilities/LivestockMembership')));
const UtilsMedicineTransactions =  Loadable(lazy(() => import('views/utilities/MedicineTransactions')));
const UtilsScheduleTransactions =  Loadable(lazy(() => import('views/utilities/ScheduleTransactions')));
const UtilsFieldAgentTransactions = Loadable(lazy(()=>import('views/utilities/FieldAgentTransactions')))
const UtilsCenter =Loadable(lazy(()=>import('views/utilities/Center')))
const UtilsOffer =Loadable(lazy(()=>import('views/utilities/Offer')))
const UtilsTask =Loadable(lazy(()=>import('views/utilities/Task')))
const UtilsFirstAid =Loadable(lazy(()=>import('views/utilities/FirstAid')))

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const Symptom=Loadable(lazy(()=>import('views/utilities/Symptom')))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        // {
        //     path: '/',
        //     element: <DashboardDefault />
        // },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-admin',
                    element: <UtilsAdmins />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-field-agent',
                    element: <UtilsFieldAgent />
                }
            ]
        },
        {
            path:'utils',
            children:[
                {
                    path:'util-symptom',
                    element:<Symptom/>
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-schedule',
                    element: <UtilsSchedule />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-task',
                    element: <UtilsTask />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-firstAid',
                    element: <UtilsFirstAid />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-center',
                    element: <UtilsCenter />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-offer',
                    element: <UtilsOffer />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-field-agent-transaction',
                    element: <UtilsFieldAgentTransactions />
                }
            ]
        },   
        {
            path: 'utils',
            children: [
                {
                    path: 'util-medicine-transaction',
                    element: <UtilsMedicineTransactions />
                }
            ]
        },        
        {
            path: 'utils',
            children: [
                {
                    path: 'util-schedule-transaction',
                    element: <UtilsScheduleTransactions />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-farmer',
                    element: <UtilsFarmer />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-doctor',
                    element: <UtilsDoctor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-service',
                    element: <UtilsService />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-medical',
                    element: <UtilsMedical />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-inventory',
                    element: <UtilsInventory />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-invoices',
                    element: <UtilsInvoices />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-symptom',
                    element: <UtilsSymptom />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-livestock',
                    element: <UtilsLivestock />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-livestock-type',
                    element: <UtilsLivestockType />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-chat',
                    element: <UtilsChat />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-livestock-membeship',
                    element: <UtilsLivestockMembershipComponent />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
