import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Typography, menuItemClasses } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem, { menuItemsWithAdmin,menuItemsFinance } from 'menu-items';


// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const appData = useSelector((state) => state.app);
    const [menuItems, setMenuItems] = useState(menuItem);

    useEffect(() => {
        console.log(appData)
        if(appData?.isAdmin=="null"){
            setMenuItems(menuItemsFinance)
        }
        else if (appData?.isAdmin=="true") {
           setMenuItems(menuItemsWithAdmin)
        } 
        else if(appData?.isAdmin=="false"){
            setMenuItems(menuItem);
        }
     
    }, [appData])

    const navItems = menuItems.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
