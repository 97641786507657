// material-ui
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { usePromiseTracker } from "react-promise-tracker";

// styles
const LoaderWrapper = styled('div')({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1301,
    width: '100%'
});

// ==============================|| LOADER ||============================== //
const Loader = () => {
    const { promiseInProgress } = usePromiseTracker();

return <>
    {promiseInProgress && <LoaderWrapper>
        <LinearProgress color="primary" />
    </LoaderWrapper>}
</>
};

export default Loader;
