import * as actionTypes from './actions';

export const initialState = {
    isAdmin: JSON.parse(localStorage.getItem('isAdmin')) || false
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const applicationReducer = (state = initialState, action) => {
    let value;
    switch (action.type) {
        case actionTypes.SET_IS_ADMIN:
            const value = action.data;
            localStorage.setItem('isAdmin', JSON.stringify(value));
            return {
                ...state,
                isAdmin: value
            };
        default:
            return state;
    }
};

export default applicationReducer;
